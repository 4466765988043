<template>
  <div class="appointmentList-view">
    <el-form v-model="form" :inline="true" label-width="100px">
      <p class="fs8 fwbold mb10">活动基础设置</p>
      <el-row>
        <el-form-item label="活动名称">
          <el-input
            type="text"
            placeholder="请输入活动名称"
            v-model="name"
            style="width: 400px"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="活动描述">
          <el-input
            type="textarea"
            placeholder="请输入活动描述"
            v-model="description"
            style="width: 400px"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="活动日期">
          <el-date-picker
              v-model="pickerValue"
              align="right"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="报名人数限制">
          <div class="d-flex align-items-center">
            <el-input
              class="w100 mr10"
              type="number"
              v-model="userNum"
              @change="() => {
                userNum =  userNum < 0 ? '' : userNum
              }">
            </el-input>
          </div>
        </el-form-item>
      </el-row>
      <p class="fs8 fwbold mb10">活动基础设置</p>
      <el-row>
        <el-form-item label="上传封面图片">
          <div class="d-flex align-items-center">
            <el-upload :action="uploadUrl" 
                       list-type="picture-card" 
                       :class="{ disabled: uploadDisabled }"
                       :on-remove="handleLogoRemove"
                       :on-success="handleSuccess" 
                       :file-list="fileList" 
                       :limit="1">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">上传图的最佳尺寸750px像素*750px像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，</div>
            </el-upload>
          </div>
        </el-form-item>
      </el-row>
      <p class="fs8 fwbold mb10">活动详情内容</p>
      <el-form-item >
        <!-- //富文本 -->
        <quill-editor
          style="height: 100%;width:1000px;padding-left:100px"
          v-model="details"
          ref="myQuillEditor"
          :options="editorOption"
        />
      </el-form-item>
      

      <p class="fs8 fwbold mb10">报名表单设置</p>
      <div v-for="(item, index) in form.detailRequests" :key="index">
        <el-row v-if="item.arfType == 'SINGLE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteSingle(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addSingle(item.childrenDetails)"
                >添加单选</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'MULTIPLE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteMultiple(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addMultiple(item.childrenDetails)"
                >添加多选</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'DROP_DOWN'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <i class="el-icon-caret-bottom"></i
              ><el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 386px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteDropDown(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'DROP_DOWN')"
                >添加单个下拉</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'TEXTAREA'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="textarea"
                :disabled="true"
                :rows="2"
                placeholder="请输入文本"
                v-model="children.title"
                style="width: 400px"
                maxlength="500"
                show-word-limit
              >
              </el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteDropDown(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'TEXTAREA')"
                >添加单个文本</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'SCOURE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入文本"
                v-model="children.title"
                maxlength="10"
                style="width: 400px"
                show-word-limit
              >
              </el-input>
              <el-rate v-model="value2" :disabled="true" :colors="colors"> </el-rate>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteDropDown(item.childrenDetails, chIndex)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'SCOURE')"
                >添加单个评分</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'IMAGE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-form-item label="图片">
            <el-upload
              :action="actionURL"
              list-type="picture-card"
              :disabled="true"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <div slot="tip" class="el-upload__tip">
                点击上传图片最大不可超过4MB
              </div>
            </el-upload>
          </el-form-item>

          <!--                    <el-row   v-for=" (children,chIndex) in item.childrenDetails">-->
          <!--                      <el-form-item label="图片数量">-->
          <!--                        <el-input-->
          <!--                                type="text"-->
          <!--                                placeholder="请输入图片上传张数(不超过9张)"-->
          <!--                                v-model="children.title"-->
          <!--                                style="width: 400px"-->
          <!--                                oninput ="value=value.match(/^\d*(\.?\d{0,2})/g)[0]"-->
          <!--                                max="9"-->
          <!--                                min="1"-->
          <!--                                maxlength="1"-->
          <!--                        ></el-input>-->
          <!--                      </el-form-item>-->
          <!--                    </el-row>-->
        </el-row>

        <el-row v-else-if="item.arfType == 'GPS'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="地址定位"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label=" ">
              <el-input
                type="text"
                placeholder="点击获取位置"
                style="width: 400px"
                :disabled="true"
                v-model="children.title"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'USERINFO'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-row>
              <el-form-item label="标题">
                <el-input
                  type="text"
                  placeholder="请输入标题"
                  v-model="children.title"
                  maxlength="10"
                  style="width: 400px"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-row>
            <el-form-item label=" ">
              <el-input
                type="text"
                placeholder="请输入内容"
                :disabled="true"
                v-model="children.title"
                maxlength="200"
                style="width: 400px"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteDropDown(item.childrenDetails, chIndex)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'USERINFO')"
                >添加单个标题内容</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.arfType == 'DATEPICKER'" class="commentForm">
          <el-row>
            <el-form-item label="日期">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-date-picker
                v-model="children.title"
                type="daterange"
                style="width: 400px"
                range-separator="至"
                :disabled="true"
                start-placeholder="选择开始日期"
                end-placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-row>
        </el-row>

        <div v-else-if="item.arfType == ''"></div>
      </div>
      <el-row style="margin-top: 10px">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          style="background-color: #0D6DFF; color: white"
          @click="dialogVisible = true"
          >添加</el-button
        >
      </el-row>

      <el-row style="margin-top: 10px">
        <el-form-item label="上下架设置">
          <el-switch
            v-model="isPutOn"
            active-text="上架"
            inactive-text="下架"
          >
          </el-switch>
        </el-form-item>
      </el-row>
      
      <el-row align="right" style="width: 800px; text-align: right">
        <el-button
          style="background-color: #0D6DFF; color: white"
          @click="saveForm"
          >发布</el-button
        >
      </el-row>
    </el-form>
    <el-dialog
      title="选择类型"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-checkbox-group v-model="checkList">
        <!--                表单类型 如单选 多选 下拉 文本框 评分 图片上传 地址定位 个人信息 日期组合选择:'ALL','SINGLE','MULTIPLE','DROP_DOWN',
      'TEXTAREA','SCOURE','IMAGE','GPS','USERINFO','DATEPICKER'-->
        <el-row>
          <el-checkbox label="SINGLE">单选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="MULTIPLE">多选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="DROP_DOWN">下拉选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="TEXTAREA">文本框</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="SCOURE">评分</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="IMAGE">图片上传</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="GPS">地址定位</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="USERINFO">个人信息</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="DATEPICKER">日期选择</el-checkbox>
        </el-row>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="selectEnter"
          style="background-color: #0D6DFF; color: white"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryAdvancedFormById,
  activityEventUpdate,
  addActivityEvent,
  activityEventDetails
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写活动内容'

export default {
  name: "appointmentList",
  data() {
    return {
      editorOption: quillConfig,
      actionURL: this.$store.state.ossUrl,
      dialogVisible: false,
      name:"",//活动名称
      description:'',//活动描述
      photos:'', // 活动封面
      userNum:1,// 活动人数
      startTime:'',//活动开始时间
      endTime:'', //活动结束时间
      details:'', //活动详情
      isPutOn:true, //是否发布
      text: "",
      radio: "",
      value: "",
      value1: "",
      value2: "",
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      options: [],
      checkList: [],
      disabled: true,
      type: "",
      pickerValue:[],
      applyAmount: 0, //活动报名数
      form: {
        title: "",
        content: "",
        detailRequests: [
          {
            arfType: "SINGLE",
            title: "",
            required: true,
            childrenDetails: [
              { arfType: "SINGLE", title: "", content: "" },
              { arfType: "SINGLE", title: "", content: "" },
            ],
          },
          
          // {arfType:'MULTIPLE',title:'多选一',childrenDetails:[{arfType:'MULTIPLE',title:'多选一'},{arfType:'MULTIPLE',title:'多选2'}]},
          // {arfType:'DROP_DOWN',title:'DROP_DOWN',childrenDetails:[{arfType:'DROP_DOWN',title:'DROP_DOWN一'},{arfType:'DROP_DOWN',title:'DROP_DOWN2'}]},
          // {arfType:'TEXTAREA',title:'TEXTAREA',childrenDetails:[{arfType:'TEXTAREA',title:'TEXTAREA1'},{arfType:'TEXTAREA',title:'TEXTAREA2'}]},
          // {arfType:'SCOURE',title:'SCOURE',childrenDetails:[{arfType:'SCOURE',title:'SCOURE1'},{arfType:'SCOURE',title:'SCOURE2'}]},
          // {arfType:'IMAGE',title:'IMAGE',childrenDetails:[{arfType:'IMAGE',title:'IMAGE1'}]},
          // {arfType:'GPS',title:'GPS',childrenDetails:[{arfType:'GPS',title:'GPS1'}]},
          // {arfType:'USERINFO',title:'USERINFO',childrenDetails:[{arfType:'USERINFO',title:'USERINFO1'},{arfType:'USERINFO',title:'USERINFO2'}]},
          // {arfType:'DATEPICKER',title:'DATEPICKER',childrenDetails:[{arfType:'DATEPICKER',title:'DATEPICKER1'}]}
        ],
      },
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      fileList:[],
      activityLogo:'',
      activityDetails:'' // 活动富文本
    };
  },
  created() {
    var startTime =new Date()
    var startDate = new Date(startTime)
    startDate.setDate(startDate.getDate() + 30)
    //检查是否编辑
    let advancedFormId = this.$route.query.aeId;
    if (advancedFormId) {
      console.log(advancedFormId);
      this.form.advancedFormId = advancedFormId
      this.type = "edit";
      this.getDetail(advancedFormId);
    }
    this.pickerValue[0] = getDataTimeSec(new Date())
    this.pickerValue[1] = getDataTimeSec(startDate)
  },
  computed: {
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
  },
  mounted(){},
  methods: {
    //移除封面
    handleLogoRemove(file, fileList) {
      this.fileList = []
      this.activityLogo = ''
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    //封面上传成功
    handleSuccess(response, file, fileList) {
      this.$message.closeAll(this.fileList);
      
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.photos = this.ossUrl +  file.response.data 
      
      this.fileList = fileList;
    },
    //请求高级表单详情
    getDetail(id) {
      this.loading = true;
      let data = {
        aeId: id,
      };
      activityEventDetails(data)
        .then((res) => {
          this.loading = false;
          console.log(res)
          this.form = res.data.form;
          this.name = res.data.activityEvent.name//活动名称
          this.description = res.data.activityEvent.description//活动描述
          this.photos = res.data.activityEvent.photos// 活动封面
          this.userNum = res.data.activityEvent.userNum // 活动人数
          this.pickerValue[0] = res.data.activityEvent.createdTime //活动开始时间
          this.pickerValue[1] = res.data.activityEvent.endTime //活动结束时间
          this.details = res.data.activityEvent.details //活动详情
          this.isPutOn = res.data.activityEvent.isPutOn 
          this.fileList = [{url:this.photos,uid:1}]
          console.log(this.form)
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    deleteCompoents(detailRequests, index) {
      detailRequests.splice(index, 1);
    },
    addSingle(singleArray) {
      if (singleArray && singleArray.length < 6) {
        singleArray.push({ arfType: "SINGLE", title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    deleteSingle(singleArray, index) {
      singleArray.splice(index, 1);
    },
    deleteMultiple(multipleArray, index) {
      multipleArray.splice(index, 1);
    },
    addMultiple(multipleArray) {
      if (multipleArray && multipleArray.length < 6) {
        multipleArray.push({ arfType: "MULTIPLE", title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    addDropDown(dropDownArray, type) {
      if (dropDownArray && dropDownArray.length < 6) {
        dropDownArray.push({ arfType: type, title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    deleteDropDown(dropDownArray, index) {
      dropDownArray.splice(index, 1);
    },
    //保存表单
    saveForm() {
      if(!this.userNum || !this.name || !this.description  || !this.details || !this.photos){
        return this.$message.error('请先完善活动内容！')
      }
      this.form.detailRequests.forEach((item, index) => {
        item.serialNumber = index + 1;
        if (item.childrenDetails) {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
          });
        }
        if (item.arfType == "IMAGE") {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
            //图片单独处理
            child.imgNum = child.title;
          });
        }
      });
      console.log(this.form)
      
      let data = {
        name:this.name,//活动名称
        description:this.description,//活动描述
        photos:this.photos, // 活动封面
        userNum:this.userNum,// 活动人数
        startTime:this.pickerValue[0],//活动开始时间
        endTime:this.pickerValue[1] , //活动结束时间
        details:this.details, //活动详情
        forms:this.form.detailRequests,
        isPutOn:this.isPutOn
      }
      
      if ((this.type == "edit")) {
        this.form.isDraft = false;
        //编辑
        
        activityEventUpdate(data).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          //去列表
          this.$router.go(-1)
        });
      } else {
        //新增
        addActivityEvent(data).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$router.go(-1)
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    selectEnter() {
      if (this.checkList.length + this.form.detailRequests.length > 10) {
        this.$message({
          type: "warning",
          message: "最多可添加10个类型",
        });
        return;
      }
      if (this.checkList && this.checkList.length > 0) {
        this.checkList.forEach((item) => {
          this.form.detailRequests.push({
            arfType: item,
            title: "",
            content: "",
            childrenDetails: [{ arfType: item, title: "", content: "" }],
          });
        });
      }
      this.dialogVisible = false;
    },
    upTop(item, index) {
      if (index == 0) {
        this.$message({
          type: "warning",
          message: "已经到顶",
        });
        return;
      } else {
        const oldDetail = this.form.detailRequests;
        const previous = oldDetail[index - 1];
        oldDetail[index - 1] = item;
        oldDetail[index] = previous;
        this.form.detailRequests = [];
        this.form.detailRequests = oldDetail;
      }
    },
    downBottom(item, index) {
      if (index == 9) {
        this.$message({
          type: "warning",
          message: "已经到顶",
        });
        return;
      } else {
        const oldDetail = this.form.detailRequests;
        const previous = oldDetail[index + 1];
        oldDetail[index + 1] = item;
        oldDetail[index] = previous;
        this.form.detailRequests = [];
        this.form.detailRequests = oldDetail;
      }
    },
    //转化时间

  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
.corporateInformation-view {
  padding: 16px 24px;
}
/deep/.el-tabs__content{
  background: #ffffff;
}
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.commentForm {
  border: 1px solid #dcdfe6;
  padding-top: 10px;
  margin-top: 10px;
}
</style>